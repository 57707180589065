var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"ma-4 elevation-4 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.seminars,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","disable-pagination":"","loading":_vm.loading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Seminars")])],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.requested_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requested_count.toLocaleString())+" ")]}},{key:"item.registered",fn:function(ref){
var item = ref.item;
return _vm._l((item.events),function(event,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(event.count)+" / "+_vm._s(event.capacity)+" ")])})}},{key:"item.date",fn:function(ref){
var item = ref.item;
return _vm._l((item.events),function(event,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.formatDate(event.start_date))+" - "+_vm._s(event.start_time)+" ")])})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
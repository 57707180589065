<template>
  <v-data-table
    :headers="headers"
    :items="seminars"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="ma-4 elevation-4 row-pointer"
    hide-default-footer
    disable-pagination
    :loading="loading"
    @click:row="handleClick"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Seminars</v-toolbar-title>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.status="{ item }">
      <span style="text-transform: capitalize;">{{ item.status }}</span>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.requested_count="{ item }">
      {{ item.requested_count.toLocaleString() }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.registered="{ item }">
      <div v-for="(event, index) in item.events" :key="index">
        {{ event.count }} / {{ event.capacity }}
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.date="{ item }">
      <div v-for="(event, index) in item.events" :key="index">
        {{ formatDate(event.start_date) }} - {{ event.start_time }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";

export default {
  mixins: [hasuraService],
  async mounted() {
    this.loading = true;
    this.seminars = (await this.campaignListAdmin()).map((c) => {
      let events = c.events.map((e) => {
        return {
          start_date: e.start_date,
          start_time: e.start_time,
          capacity: e.capacity,
          count: e.households.reduce((a, b) => {
            return a + b.people_aggregate.aggregate.count;
          }, 0),
        };
      });
      return {
        id: c.id,
        venue_name: c.venue_name,
        user_id: c.user_id,
        user: c.user.name,
        city: c.city,
        status: c.status,
        state: c.state,
        requested_count: c.requested_count,
        date: c.events[0].start_date,
        events: events,
        registered: events.reduce((currentVal, event) => {
          return currentVal + event.count;
        }, 0),
      };
    });
    this.loading = false;
  },
  methods: {
    handleClick(e) {
      this.$router.push(`/seminar/${e.id}`);
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
  },
  data() {
    return {
      loading: false,
      sortBy: "date",
      sortDesc: true,

      headers: [
        {
          text: "Agent",
          value: "user",
        },
        {
          text: "Venue",
          value: "venue_name",
        },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Status", value: "status" },
        { text: "Mailed", value: "requested_count" },
        { text: "Registered", value: "registered" },
        { text: "Date", value: "date" },
      ],
      seminars: [],
    };
  },
};
</script>
